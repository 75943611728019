<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  //各省份的地图json文件
  var provinces = {
      '上海': 'mapJson/shanghai.json',
      '河北': 'mapJson/hebei.json',
      '山西': 'mapJson/shanxi.json',
      '内蒙古': 'mapJson/neimenggu.json',
      '辽宁': 'mapJson/liaoning.json',
      '吉林': 'mapJson/jilin.json',
      '黑龙江': 'mapJson/heilongjiang.json',
      '江苏': 'mapJson/jiangsu.json',
      '浙江': 'mapJson/zhejiang.json',
      '安徽': 'mapJson/anhui.json',
      '福建': 'mapJson/fujian.json',
      '江西': 'majiangxi.json',
      '山东': 'mapJson/shandong.json',
      '河南': 'mapJson/henan.json',
      '湖北': 'mapJson/hubei.json',
      '湖南': 'mapJson/hunan.json',
      '广东': 'mapJson/guangdong.json',
      '广西': 'mapJson/guangxi.json',
      '海南': 'mapJson/hainan.json',
      '四川': 'mapJson/sichuan.json',
      '贵州': 'mapJson/guizhou.json',
      '云南': 'mapJson/yunnan.json',
      '西藏': 'mapJson/xizang.json',
      '陕西': 'mapJson/shannxi.json',
      '甘肃': 'mapJson/gansu.json',
      '青海': 'mapJson/qinghai.json',
      '宁夏': 'mapJson/ningxia.json',
      '新疆': 'mapJson/xinjiang.json',
      '北京': 'mapJson/beijing.json',
      '天津': 'mapJson/tianjin.json',
      '重庆': 'mapJson/chongqing.json',
      '香港': 'mapJson/xianggang.json',
      '澳门': 'mapJson/aomen.json'
  };
  
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      data: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      areaName:{
        type:String,
        default:''
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null,
        name:'china',
      }
    },
    watch:{
      'data':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          if(!this.areaName){
            return
          }
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption(provinces[this.areaName], this.areaName)
          let _this = this
          // //单击切换到省级地图，当mapCode有值,说明可以切换到下级地图
          this.chart.on('click', function(params) {
            if(params.seriesName=='icon'){
              const { href } = _this.$router.resolve({
                  name: "siteHome",
                  query: {
                      id:params.data.datas,
                      name:params.data.name
                  }
              });
              window.open(href, '_blank');
            } 
          });
          //捕捉georoam事件，使下层的geo随着上层的geo一起缩放拖曳
          this.chart.on('georoam',function(params){
              var option = _this.chart.getOption();//获得option对象
            if(params.zoom!=null&&params.zoom!=undefined){ //捕捉到缩放时
                option.geo[0].zoom = option.series[0].zoom;//下层geo的缩放等级跟着上层的geo一起改变
                option.geo[0].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
                option.geo[1].zoom = option.series[0].zoom;//下层geo的缩放等级跟着上层的geo一起改变
                option.geo[1].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
                option.geo[2].zoom = option.series[0].zoom;//下层geo的缩放等级跟着上层的geo一起改变
                option.geo[2].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
                option.geo[3].zoom = option.series[0].zoom;//下层geo的缩放等级跟着上层的geo一起改变
                option.geo[3].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
                option.geo[4].zoom = option.series[0].zoom;//下层geo的缩放等级跟着上层的geo一起改变
                option.geo[4].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
            }else{//捕捉到拖曳时
                option.geo[0].center = option.series[0].center ;//下层的geo的中心位置随着上层geo一起改变
                option.geo[1].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
                option.geo[2].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
                option.geo[3].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
                option.geo[4].center = option.series[0].center;//下层的geo的中心位置随着上层geo一起改变
            }
            _this.chart.setOption(option);//设置option
          });
      },
      setOption(path, name){
          if(path&&name){
            let chinaJson = require(`@/utils/${path}`)
            echarts.registerMap(name, chinaJson)
            this.name = name
          }
          this.chart.setOption({
            visualMap: {
                show:false,
                type: 'continuous',
                showLabel: true,
                left: '50',
                min: 0,
                inRange: {
                    color: ['#00C1DE', '#00C1DE']
                }
            },
            geo: [
            {
                map:this.name,
                animationDurationUpdate:0,
                aspectScale: 1,
                zoom: 0.65,
                layoutCenter: ["50%", "52%"],
                layoutSize: "180%",
                show: true,
                roam: true,
               
                emphasis: {
                  label: {
                    show: false,
                  },
                },
                itemStyle: {
                    borderColor: "#c0f3fb",
                    borderWidth: 1,
                    shadowColor: "#8cd3ef",
                    shadowOffsetY: 10,
                    shadowBlur: 120,
                    areaColor: "transparent",
                }
            },
            // 重影
            {
                type: "map",
                animationDurationUpdate:0,
                map: this.name,
                zlevel: -1,
                aspectScale: 1,
                zoom: 0.65,
                layoutCenter: ["50%", "51%"],
                layoutSize: "180%",
                roam: true,
                silent: true,
                itemStyle: {
                    borderWidth: 1,
                    // borderColor:"rgba(17, 149, 216,0.6)",
                    borderColor: "rgba(58,149,253,0.8)",
                    shadowColor: "rgba(172, 122, 255,0.5)",
                    shadowOffsetY: 5,
                    shadowBlur: 15,
                    areaColor: "rgba(5,21,35,0.1)",
                },
            },
            {
                type: "map",
                animationDurationUpdate:0,
                map: this.name,
                zlevel: -2,
                aspectScale: 1,
                zoom: 0.65,
                layoutCenter: ["50%", "52%"],
                layoutSize: "180%",
                roam: true,
                silent: true,
                itemStyle: {
                    borderWidth: 1,
                    // borderColor: "rgba(57, 132, 188,0.4)",
                    borderColor: "rgba(58,149,253,0.6)",
                    shadowColor: "rgba(65, 214, 255,1)",
                    shadowOffsetY: 5,
                    shadowBlur: 15,
                    areaColor: "transpercent",
                },
            },
            {
                type: "map",
                map:this.name,
                zlevel: -3,
                animationDurationUpdate:0,
                aspectScale: 1,
                zoom: 0.65,
                layoutCenter: ["50%", "53%"],
                layoutSize: "180%",
                roam: true,
                silent: true,
                itemStyle: {
                  borderWidth: 1,
                  // borderColor: "rgba(11, 43, 97,0.8)",
                  borderColor: "rgba(58,149,253,0.4)",
                  shadowColor: "rgba(58,149,253,1)",
                  shadowOffsetY: 15,
                  shadowBlur: 10,
                  areaColor: "transpercent",
                },
            },
            {
                type: "map",
                animationDurationUpdate:0,
                map: this.name,
                zlevel: -4,
                aspectScale: 1,
                zoom: 0.65,
                layoutCenter: ["50%", "54%"],
                layoutSize: "180%",
                roam: true,
                silent: true,
                itemStyle: {
                  borderWidth: 5,
                  // borderColor: "rgba(11, 43, 97,0.8)",
                  borderColor: "rgba(5,9,57,0.8)",
                  shadowColor: "rgba(29, 111, 165,0.8)",
                  shadowOffsetY: 15,
                  shadowBlur: 10,
                  areaColor: "rgba(5,21,35,0.1)",
                },
            },
            ],
            series: [
            {
                name: "接入区域",
                type: "map",
                map: this.name, // 自定义扩展图表类型
                aspectScale: 1,
                zoom: 0.65, // 缩放
                roam: true,
                showLegendSymbol: true,
                label: {
                    show: false,
                },
                selectedMode: 'false',
                itemStyle: {
                    areaColor: {
                        type: "linear",
                        x: 1200,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [
                            {
                            offset: 0,
                            color: "rgba(3,27,78,0.75)", // 0% 处的颜色
                            },
                            {
                            offset: 1,
                            color: "rgba(58,149,253,0.75)", // 50% 处的颜色
                            },
                        ],
                        global: true, // 缺省为 false
                    },
                    borderColor: "#fff",
                    borderWidth: 0.2,
                    
                },
                emphasis: {
                  itemStyle: {
                      show: false,
                      color: "#fff",
                      areaColor: "rgba(0,254,233,0.6)",
                  }
                },
                layoutCenter: ["50%", "50%"],
                layoutSize: "180%",
                markPoint: {
                    symbol: "none",
                },
                data: this.data,
            },
            {
                type: 'scatter',
                name:'icon',
                coordinateSystem: 'geo',
                itemStyle: {
                    color: '#f00',
                },
                symbol: `image://${require('/public/posi.png')}`,
                symbolSize: [20, 25],
                symbolOffset: [0, -22],
                z: 9999,
                data: this.data,
            },
            {
                type: 'scatter',
                coordinateSystem: 'geo',
                label: {
                    show: true,
                    formatter: function (params) {
                        var name = params.name;
                        var data = params.data.datas;
                        var text = `{tline|${name}}`;
                        return text;
                    },
                    color: '#fff',
                    rich: {
                        tline: {
                            padding: [3, 10],
                            color: '#fff',
                            fontSize: 12,
                            backgroundColor:'#000'
                        },
                    },
                },
                emphasis: {
                  label:{
                    show: true,
                  }   
                },
                itemStyle: {
                    color: '#00FFF600',
                },
                z: 999,
                data: this.data,
            }]
          })    
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>